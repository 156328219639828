.verify[data-v-4f24737d] {
  height: 100%;
  background: url(/left-img-4d8e823c-yb.png) left center no-repeat;
  padding-left: 350px;
  padding-top: 70px;
  box-sizing: border-box;
}
.verify .summary[data-v-4f24737d] {
    text-align: center;
    line-height: 1.5;
    margin: 50px auto 30px;
}
.verify .tip[data-v-4f24737d] {
    text-align: center;
    line-height: 1.5;
    margin: 10px auto 30px;
}
.verify .un-verify-tip[data-v-4f24737d] {
    font-size: 12px;
    color: red;
    padding-top: 5px;
}
.verify .verify-btn[data-v-4f24737d] {
    background: none;
    color: #32d182;
}
.verify .verify-btn.disabled[data-v-4f24737d] {
      color: #fff;
}
.verify .form[data-v-4f24737d] {
    margin: 0 auto;
}
a[data-v-4f24737d]:link {
  text-decoration: underline;
  color: blue;
}
