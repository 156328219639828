* {
  box-sizing: border-box;
}
body {
  color: #333;
  font-size: 16px;
  font-family: 'PingFang SC', 'HanHei SC', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
#app {
  width: 740px;
  height: 480px;
  overflow: hidden;
  margin: 0 auto;
}
a {
  text-decoration: none;
  outline: none;
  color: #333;
}
a:active {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #333;
}
a:visited {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
  color: #333;
}
.form {
  width: 280px;
}
.form-group {
  margin-bottom: 10px;
}
input, button {
  outline: none;
}
.input-role {
  width: 100%;
  display: block;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  outline: none;
}
.input-role:focus {
  border-color: #32d182;
}
.input-role::-webkit-input-placeholder {
  color: #ccc;
}
.input-role::-moz-placeholder {
  color: #ccc;
}
.input-role:-ms-input-placeholder {
  color: #ccc;
}
.input-role::-ms-input-placeholder {
  color: #ccc;
}
.input-role::placeholder {
  color: #ccc;
}
.disabled {
  background: #ccc !important;
  cursor: not-allowed !important;
  border-color: #ccc !important;
}
.sub-btn {
  width: 100%;
  display: block;
  background: #32d182;
  border: 1px solid #32d182;
  line-height: 38px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  outline: none;
  text-align: center;
}
.messages-tips {
  font-size: 12px;
  line-height: 1;
  color: red;
  padding-top: 5px;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
    clear: both;
    content: '.';
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
@font-face {
  font-family: 'ybicon';
  src: url(/ybicon-08801a42-yb.eot);
  src: url(/ybicon-08801a42-yb.eot#iefix) format("embedded-opentype"), url(/ybicon-57f7a1f7-yb.ttf) format("truetype"), url(/ybicon-863284b6-yb.woff) format("woff"), url(/ybicon-8fd9ef5e-yb.svg#ybicon) format("svg");
  font-weight: normal;
  font-style: normal;
}
