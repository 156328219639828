
.certification[data-v-17d4dbe6]{
    height: 100%;
    background: url(/left-bg-7e141a3b-yb.png) left center no-repeat;
    padding-left: 410px;
    padding-top: 45px;
    box-sizing: border-box;
    position: relative;
    background-size:350px 100% ;
}
.certification-user[data-v-17d4dbe6]{
    position: absolute;
    width: 350px;
    left: 0;
    top:50%;
    transform: translate3d(0,-50%,0);
    text-align: center;
}
.certification-user-img[data-v-17d4dbe6]{
    width: 56px;
    height: 56px;
    border-radius:50% ;
    background: #fff;
    margin-bottom: 10px;
}
.certification-user-name[data-v-17d4dbe6]{
    font-size: 16px;
    color: #fff;
}
.loginout[data-v-17d4dbe6] {
    font-size: 12px;
    margin-top: 10px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}
.certification-form-title[data-v-17d4dbe6]{
    font-size: 18px;
    color: #00816a;
    text-align: center;
    margin-bottom: 20px;
}
.certification-form-sup[data-v-17d4dbe6]{
    position: relative;
    color: #999;
    font-size: 14px;
    text-align: center;
    height: 50px;
    line-height: 50px;
}
.certification-form-sup span[data-v-17d4dbe6]{
    display: inline-block;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 0 10px;
}
.certification-form-sup[data-v-17d4dbe6]:before{
    position: absolute;
    z-index: 1;
    content: '';
    font-size: 0;
    height: 1px;
    width: 100%;
    left: 0;
    top:50%;
    background: #dadedd;
}
.captcha-input[data-v-17d4dbe6]{
    width: 50%;
    display: inline-block;
}
.captcha-img[data-v-17d4dbe6]{
    width:40%;
    cursor: pointer;
    vertical-align: bottom;
    float: right;
}
