.btn[data-v-a5ffbb3a] {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: normal;
  border: 1px solid;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 3px;
}
.btn-primary[data-v-a5ffbb3a] {
  color: #ffffff;
  border-color: #00816a;
  background-color: #00816a;
}
.btn-primary[data-v-a5ffbb3a]:hover {
  background-color: #006f59;
}
.btn-primary[data-v-a5ffbb3a]:active {
  background-color: #005f49;
}
.btn-default[data-v-a5ffbb3a] {
  color: #222222;
  border-color: #e3e3e3;
  background-color: #f5f5f5;
}
.btn-default[data-v-a5ffbb3a]:hover {
  background-color: #f0f0f0;
}
.btn-default[data-v-a5ffbb3a]:active {
  background-color: #e9e9e9;
}
.btn[data-v-a5ffbb3a]:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #999999;
}
.btn-sm[data-v-a5ffbb3a] {
  font-size: 12px;
}
.btn-md[data-v-a5ffbb3a] {
  font-size: 14px;
  padding: 9px 18px;
}
.btn-lg[data-v-a5ffbb3a] {
  font-size: 16px;
  padding: 12px 24px;
}
[class^="yb-"][data-v-a5ffbb3a], [class*=" yb-"][data-v-a5ffbb3a] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ybicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.yb-yuan[data-v-a5ffbb3a]:before {
  content: "\E910";
}
.yb-down[data-v-a5ffbb3a]:before {
  content: "\E911";
}
.yb-addpic[data-v-a5ffbb3a]:before {
  content: "\E90E";
}
.yb-up[data-v-a5ffbb3a]:before {
  content: "\E90F";
}
.yb-name[data-v-a5ffbb3a]:before {
  content: "\E90C";
}
.yb-email[data-v-a5ffbb3a]:before {
  content: "\E90D";
}
.yb-works[data-v-a5ffbb3a]:before {
  content: "\E906";
}
.yb-new-picture[data-v-a5ffbb3a]:before {
  content: "\E908";
}
.yb-new-words[data-v-a5ffbb3a]:before {
  content: "\E909";
}
.yb-succeed[data-v-a5ffbb3a]:before {
  content: "\E905";
}
.yb-company[data-v-a5ffbb3a]:before {
  content: "\E900";
}
.yb-hamburger[data-v-a5ffbb3a]:before {
  content: "\E901";
}
.yb-lock[data-v-a5ffbb3a]:before {
  content: "\E902";
}
.yb-more[data-v-a5ffbb3a]:before {
  content: "\E903";
}
.yb-phone[data-v-a5ffbb3a]:before {
  content: "\E904";
}
.yb-add2[data-v-a5ffbb3a]:before {
  content: "\E907";
}
.yb-added[data-v-a5ffbb3a]:before {
  content: "\E929";
}
.yb-add[data-v-a5ffbb3a]:before {
  content: "\E92A";
}
.yb-addtosubmit[data-v-a5ffbb3a]:before {
  content: "\E92B";
}
.yb-alipay[data-v-a5ffbb3a]:before {
  content: "\E92C";
}
.yb-next[data-v-a5ffbb3a]:before {
  content: "\E92D";
}
.yb-cc-by[data-v-a5ffbb3a]:before {
  content: "\E92F";
}
.yb-cc2[data-v-a5ffbb3a]:before {
  content: "\E930";
}
.yb-cc0[data-v-a5ffbb3a]:before {
  content: "\E931";
}
.yb-choose-off[data-v-a5ffbb3a]:before {
  content: "\E932";
}
.yb-close-alt[data-v-a5ffbb3a]:before {
  content: "\E933";
}
.yb-cm[data-v-a5ffbb3a]:before {
  content: "\E935";
}
.yb-verify-dna[data-v-a5ffbb3a]:before {
  content: "\E937";
}
.yb-download[data-v-a5ffbb3a]:before {
  content: "\E938";
}
.yb-earth[data-v-a5ffbb3a]:before {
  content: "\E939";
}
.yb-feedback[data-v-a5ffbb3a]:before {
  content: "\E93A";
}
.yb-view[data-v-a5ffbb3a]:before {
  content: "\E93B";
}
.yb-info2[data-v-a5ffbb3a]:before {
  content: "\E93C";
}
.yb-layer[data-v-a5ffbb3a]:before {
  content: "\E93D";
}
.yb-license[data-v-a5ffbb3a]:before {
  content: "\E93E";
}
.yb-media-link[data-v-a5ffbb3a]:before {
  content: "\E93F";
}
.yb-message[data-v-a5ffbb3a]:before {
  content: "\E940";
}
.yb-morelike[data-v-a5ffbb3a]:before {
  content: "\E941";
}
.yb-cc-nb[data-v-a5ffbb3a]:before {
  content: "\E942";
}
.yb-cc-nd[data-v-a5ffbb3a]:before {
  content: "\E943";
}
.yb-paper[data-v-a5ffbb3a]:before {
  content: "\E946";
}
.yb-user[data-v-a5ffbb3a]:before {
  content: "\E947";
}
.yb-picture[data-v-a5ffbb3a]:before {
  content: "\E949";
}
.yb-recent[data-v-a5ffbb3a]:before {
  content: "\E94A";
}
.yb-refresh[data-v-a5ffbb3a]:before {
  content: "\E94B";
}
.yb-cc-sa[data-v-a5ffbb3a]:before {
  content: "\E94C";
}
.yb-search[data-v-a5ffbb3a]:before {
  content: "\E94D";
}
.yb-send-media[data-v-a5ffbb3a]:before {
  content: "\E94E";
}
.yb-shield[data-v-a5ffbb3a]:before {
  content: "\E94F";
}
.yb-exit[data-v-a5ffbb3a]:before {
  content: "\E950";
}
.yb-tag[data-v-a5ffbb3a]:before {
  content: "\E951";
}
.yb-transaction[data-v-a5ffbb3a]:before {
  content: "\E952";
}
.yb-delete[data-v-a5ffbb3a]:before {
  content: "\E953";
}
.yb-vip[data-v-a5ffbb3a]:before {
  content: "\E954";
}
.yb-wallet[data-v-a5ffbb3a]:before {
  content: "\E955";
}
.yb-wechat[data-v-a5ffbb3a]:before {
  content: "\E956";
}
.yb-wechatpay[data-v-a5ffbb3a]:before {
  content: "\E957";
}
.yb-sendmedia[data-v-a5ffbb3a]:before {
  content: "\E958";
}
.yb-info[data-v-a5ffbb3a]:before {
  content: "\E959";
}
.yb-box[data-v-a5ffbb3a]:before {
  content: "\E95B";
}
.yb-select[data-v-a5ffbb3a]:before {
  content: "\E95D";
}
.yb-last[data-v-a5ffbb3a]:before {
  content: "\E927";
}
.yb-close[data-v-a5ffbb3a]:before {
  content: "\E90A";
}
.yb-star-checked[data-v-a5ffbb3a]:before {
  content: "\E926";
}
.yb-star[data-v-a5ffbb3a]:before {
  content: "\E90B";
}
.pager[data-v-a5ffbb3a] {
  position: relative;
  margin-top: 18px;
  height: 26px;
  text-align: center;
  font-size: 0;
  color: #222222;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pager > div[data-v-a5ffbb3a] {
    display: inline-block;
    font-size: 12px;
}
.pager .page-total[data-v-a5ffbb3a] {
    font-size: 12px;
    color: #999999;
    float: left;
    line-height: 26px;
}
.pager .fr[data-v-a5ffbb3a] {
    float: right;
    font-size: 0;
}
.pager .item[data-v-a5ffbb3a] {
    position: relative;
    display: inline-block;
    min-width: 26px;
    padding: 0 5px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    margin-left: 10px;
    font-size: 12px;
    border: 1px solid #dadedd;
}
.pager .item i[data-v-a5ffbb3a] {
      line-height: 26px;
      font-size: 12px;
}
.pager .item.nav[data-v-a5ffbb3a] {
      color: #999999;
}
.pager .item[data-v-a5ffbb3a]:hover, .pager .item.active[data-v-a5ffbb3a] {
      color: #ffffff;
      background-color: #00816a;
      border-color: #00816a;
}
.pager .item.disabled[data-v-a5ffbb3a], .pager .item.disabled[data-v-a5ffbb3a]:hover {
      cursor: not-allowed;
      color: #cccccc;
      background-color: #f0f0f0 !important;
      border-color: #dadedd;
}
.pager .goto[data-v-a5ffbb3a] {
    display: inline-block;
    margin-left: 16px;
    font-size: 0;
}
.pager .goto .input-box[data-v-a5ffbb3a] {
      display: inline-block;
      color: #999999;
      font-size: 12px;
}
.pager .goto .input-box input[data-v-a5ffbb3a] {
        width: 26px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #dadedd;
        margin: 0 2px;
}
.pager .goto .input-box input[data-v-a5ffbb3a]:focus {
          border-color: #00816a;
}
.pager .goto .input-box input[data-v-a5ffbb3a]::-webkit-outer-spin-button, .pager .goto .input-box input[data-v-a5ffbb3a]::-webkit-inner-spin-button {
          -webkit-appearance: none;
}
.pager .goto .input-box input[type="number"][data-v-a5ffbb3a] {
          -moz-appearance: textfield;
}
.pager .goto .goto-btn[data-v-a5ffbb3a] {
      float: right;
      width: 42px;
      height: 26px;
      cursor: pointer;
      line-height: 22px;
      text-align: center;
      border-radius: 2px;
      margin: 0 0 0 10px;
      border: 1px solid #dadedd;
      background: none;
      color: #999999;
      font-size: 12px;
}
.pager .goto .goto-btn[data-v-a5ffbb3a]:hover {
        color: #00816a;
        border-color: #00816a;
}
.pager .goto .goto-btn[data-v-a5ffbb3a]:disabled, .pager .goto .goto-btn[data-v-a5ffbb3a]:disabled:hover {
        cursor: not-allowed;
        color: #cccccc;
        background-color: #f0f0f0;
}
