
.nav[data-v-1b2d0c7b]{
    font-size: 22px;
    margin-bottom: 60px;
}
.nav-item[data-v-1b2d0c7b]{
    text-align: center;
    color: #ccc;
    padding: 0 30px;
    line-height: 1;
    border-left:1px solid #ccc ;
}
.nav-item[data-v-1b2d0c7b]:nth-child(1){
    border-left: none;
}
.router-link-active[data-v-1b2d0c7b]{
    color: #333;
}
