.login .out-link[data-v-c40149d6] {
  font-size: 12px;
  color: #00816a;
  line-height: 38px;
}
.login .captcha-input[data-v-c40149d6] {
  width: 50%;
  display: inline-block;
}
.login .captcha-img[data-v-c40149d6] {
  width: 40%;
  cursor: pointer;
  vertical-align: bottom;
  float: right;
}
